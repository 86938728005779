import React, {useEffect} from 'react'
import {Box, Grid} from "@mui/material";
import useStyles from "./styles";
import ProfilePicture from "./SubComponents/ProfilePicture";
import CareerGoal from "./SubComponents/CareerGoal";
import CustomContainer from "../../SharedComponents/CustomContainer";
import {InitWOW} from "../../Utils";

const About = () => {
    const classes = useStyles()

    useEffect(() => {
        InitWOW()
    }, [])
    return <Box aria-label={'about section'} id={'about'} className={classes.aboutContainer}>
        <CustomContainer>
            <Grid container spacing={1} component={Box} height={'100%'} my={'auto'}>
                <Grid xs={12} md={6} item aria-label={'Banner'}
                      container
                      justifyContent={'center'}
                      alignItems={'center'}>
                    <Grid item xs className={'wow fadeInLeftBig'} data-wow-duration="0.5s" data-wow-delay="0.2s">
                        <ProfilePicture/>
                    </Grid>

                </Grid>
                <Grid xs={12} md={6} item aria-label={'About Details'} container justifyContent={'center'}
                      alignItems={'center'}>
                    <Grid item xs className={'wow fadeInRightBig'} data-wow-delay="0.5s" data-wow-duration="0.5s">
                        <CareerGoal/>
                    </Grid>
                </Grid>
            </Grid>
        </CustomContainer>
    </Box>
}

export default About
