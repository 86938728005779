import React from 'react'
import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

const TopSpace = styled('div', {
    shouldForwardProp(propName) {
        return propName
    }
})(({theme}) => {
    return {
        ...theme.mixins.toolbar
    }
})

const MainBody = ({children, ...rest}) => {
    return <Box height={'100%'} component={'section'} {...rest}>
        <TopSpace/>
        {children}
    </Box>
}
export default MainBody
