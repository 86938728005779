import React, {useState, createContext} from 'react'


export const ThemeSettingsContext = createContext({
    isDark: false,
    updateTheme: () => {
    },
});


const ThemeSettings = ({props, children}) => {
    const [isDark, setIsDark] = useState(false)

    const updateTheme = (value) => {
        setIsDark(value)
    }
    return <ThemeSettingsContext.Provider value={{
        isDark, updateTheme
    }}>
        {children}
    </ThemeSettingsContext.Provider>
}

export default ThemeSettings
