import {Container} from "@mui/material";


const CustomContainer = ({children, ...rest}) => {
    const props = {
        maxWidth: 'xl',
        ...rest
    }
    return <Container {...props}>
        {children}
    </Container>
}


export default CustomContainer
