import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => {
    return {
        aboutContainer: {
            display: 'flex',
            minHeight: 'max(50%, 600px)',
            backgroundImage: `linear-gradient(to bottom right, ${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
            width: '100%',
            paddingBlock:theme.spacing(4)
        }
    }
})

export default useStyles
