import {styled} from "@mui/material/styles";

const MainComponent = styled('main', {
    shouldForwardProp(propName) {
        return propName
    }
})(({theme}) => {
    return {
        background: theme.palette.background.default,
        height: '100vh'
    }
})


const Main = ({children}) => {
    return <MainComponent>
        {children}
    </MainComponent>
}

export default Main
